<template src="./PasswordRecovery.html"></template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
import LoginService from "@/services/Login";
import { recoverPasswordConstants } from "@/utils/constants";

export default {
  name: "password-recovery",
  data() {
    return {
      user: {
        document: "",
        send_email: "",
      },
      recoverState: "waiting",
      loading: false,
      step: 1,
      mailList: [],
    };
  },
  validations: {
    user: {
      document: { required, minLength: minLength(4) },
    },
  },
  methods: {
    async continueRecovery() {
      try {
        await LoginService.getExistingAccount(this.user.document);
        this.loading = true;
        this.step = 2;
        this.mailList = await LoginService.getEmailsUsers(this.user.document);
        this.loading = false;
      } catch (e) {
        this.recoverState = "error";
      }
    },
    async sendPasswordRecovery() {
      try {
        this.loading = true;
        const response = await LoginService.sendRecoverPassword(this.user);
        this.loading = false;
        if (
          response.message === recoverPasswordConstants.EMAIL_SENT_SUCCESSFYLLY
        ) {
          this.step = 3;
        } else if (
          response.message === recoverPasswordConstants.USER_DOES_NOT_EXIST
        ) {
          this.recoverState = "error";
        }
      } catch (e) {
        this.recoverState = "error";
        this.$buefy.toast.open({
          duration: 1000,
          message: e.response.data.message,
          position: "is-bottom",
          type: "is-danger",
        });
      }
    },
    async passwordRecovery() {
      try {
        this.loading = true;
        const response = await LoginService.sendRecoverPassword(this.user);
        this.loading = false;
        if (
          response.message === recoverPasswordConstants.EMAIL_SENT_SUCCESSFYLLY
        ) {
          this.$buefy.toast.open({
            duration: 5000,
            position: "is-bottom",
            message: "Correo enviado con exito!",
            type: "is-success",
          });
        } else if (
          response.message === recoverPasswordConstants.USER_DOES_NOT_EXIST
        ) {
          this.recoverState = "error";
        }
      } catch (error) {
        this.recoverState = "error";
        this.$buefy.toast.open({
          duration: 1000,
          message: error.response.data.message,
          position: "is-bottom",
          type: "is-danger",
        });
      }
    },
    backToLogin() {
      this.$router.push({ name: "Login" });
    },
  },
};
</script>

<style lang="scss" src="./PasswordRecovery.scss" scoped></style>
